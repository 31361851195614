<script>
// import Layout from "../../layouts/main";
// import PageHeader from "@/components/page-header";
//validation
import { required , minLength , maxLength , between } from "vuelidate/lib/validators";

// --/validation
import loginRegModal from "../popUpModals/loginReg";
import termsAndCondition from "../popUpModals/termsAndCondition";
import footerLayout from "../../layouts/footerLayout";
import appConfig from "@/app.config";
import ClassOnScroll from 'vue-class-on-scroll'
import simplebar from "simplebar-vue";

import Drawer from "vue-simple-drawer"

import jwt_decode from "jwt-decode";




import PincodeInput from 'vue-pincode-input';

import VueCountdown from '@chenfengyuan/vue-countdown';

import VueGoogleAutocomplete from 'vue-google-autocomplete'
import Vue from 'vue';
// import registerCopyVue from '../account/register - Copy.vue';

export default {

name: "FormValidation",
validations: {
    firstname: {
        required,
        minLength:minLength(10),
        maxLength:minLength(10),
    },
    //  title: {
    //     required,
    //     minLength:minLength(100),
    //      maxLength:minLength(100),
    // },
    // Company: {
    //     required,
    //     minLength:minLength(100),
    //      maxLength:minLength(100),
    // },
    // phone: {
    //     required,
    //     numeric,
    //     minLength: minLength(10),
    //     maxLength: maxLength(10),
    // },
},

  page: {
    title: "Cloud Kitch",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    // Layout,
    // PageHeader,
    loginRegModal,
    termsAndCondition,
    footerLayout,
	simplebar,
    PincodeInput,
    VueCountdown,
    VueGoogleAutocomplete,
    Drawer
  },
  directives: {
  'class-on-scroll': ClassOnScroll,
},
 data() {
        return {
            fullName:'',
            emailID:'',
            location:'',
            mobileNo:'',
            message:'',
            title:'',
            emPanelError:'',
		   openMenuDrawer: false,
            emailIDforReg:'',
            searching: 0,
            changedInputValue:'',
            isValNum:0,
            countryCode:'+91',
            email:'',
            contact:'',
            code:'',
             show: false,
             counting: false,
        variants: ['primary', 'secondary', 'success', 'warning', 'danger', 'info', 'light', 'dark', 'white'],
        // headerBgVariant: 'dark',
        // headerTextVariant: 'light',
        // bodyBgVariant: 'light',
        // bodyTextVariant: 'dark',
        footerBgVariant: 'light',
        footerTextVariant: 'dark',
            isHidden: true,
            authError: '',
            isAuthError: false,
            regName:'',
            regEmail:'',
            regCorporateCode:'',
            regerror:'',
            hiddenOTPReg: true,
            codeReg: '',
            corporatesBranches: undefined,
            selectedBranchID:'',
            copBranchError:'',
            regSendOTPData: 0,
            regVerifyOTPData: 0,
            sendOTPData: 0,
            verifyOTPData: 0,
            userName:'',
            localStorageUserData: '',
            params: {
                    client_id: "855252858658-sihf66i67ut7cdul3hfm132b95r8agct.apps.googleusercontent.com"
                },
                // only needed if you want to render the button with the google ui
                renderParams: {
                    width: 250,
                    height: 50,
                    longtitle: true
                }
        }
     }, 
     mounted()
     {
        this.getUserData();
        
        this.autoRefreshToken();
     },
methods: {

    addEmpanel()
		{
			let getEmpanelDetails = JSON.stringify({ 
				fullName: this.fullName,
				// title: this.title,
				company: this.company,
				mobileNo: this.mobileNo,
                emailID: this.emailID,
                location:this.location,
                // city:this.city,
                // country:this.country,
                message:this.message
				});
			this.axios.post('/insertCorporateByWeb', getEmpanelDetails)
			.then((response)=>{
			// console.log(response.data.cuisineDetails);
			let status = response.status;
			if(status == 200 && response.data.status == 200)
			{
                location.reload();
            }
            else if(status == 200 && response.data.status == 204)
            {
                this.emPanelError = response.data.message;
            }
            }).catch((error) => {
			    console.log(error.response.data.message);
			});
		},

    autoRefreshToken()
		{
			if (localStorage.getItem('uTid') && localStorage.getItem('uTid') != null) 
			{
				var getExp = jwt_decode(localStorage.getItem('uTid'));
				var currentDate  = new Date();
				var tokenExpire = new Date(getExp.exp * 1000);
				var difference = tokenExpire.getTime() - currentDate.getTime(); // This will give difference in milliseconds;
				var resultInMinutes = Math.round(difference / 60000);
				if(resultInMinutes <= 15 && resultInMinutes >= 0)
				{
					this.refreshTokens();
				}
				else if (resultInMinutes < 0) {
                    this.refreshTokens();
					// localStorage.clear();
					// Vue.prototype.$userData = '';
					// Vue.prototype.$placesAddress = '';
					// // this.$router.push('/');
					// window.location.href = '/';
				}
			}
		},

		refreshTokens()
		{
			// alert();
			 this.axios.get('/autoRefreshToken')
                    .then((response)=>{
					// console.log(response);
					let status = response.status;
					if(status == 200 && response.data.status == 200 && response.data.refreshedToken && response.data.refreshedToken != '')
					{
						console.log(response.data.refreshedToken);
						var userData = {
                                "utid":response.data.refreshedToken,
                            }
                            localStorage.setItem('uTid',JSON.stringify(userData));
							location.reload();
					} // if token is expired so it can be refreshed
					}).catch((error) => {
						// console.log(error);
				});
		},

    toggleMenuDrawer() {
			this.openMenuDrawer = !this.openMenuDrawer
		},
        openLoginModal()
		{
			this.openMenuDrawer = !this.openMenuDrawer;
			this.$bvModal.show("modal-login");

		},
    getUserData()
    {
        this.axios.get('/getUserDeatils')
                    .then((response)=>{
					// console.log(response);
                    // if(response.data.token && response.data.token != '')
					// {
					// 	var userData = {
                    //             "utid":response.data.token,
                    //         }
                    //         localStorage.setItem('uTid',JSON.stringify(userData));
					// } // if token is expired so it can be refreshed
					let status = response.status;
					if(status == 200 && response.data.status == 200)
					{
						if (response.data.userData.userName) {
                            this.userName = response.data.userData.userName;
                        }
                        else
                        {
                            this.userName = '';
                        }
                        if(response.data.userData.isTncAgreed == 0)
                        {
                            this.$bvModal.show("modal-termsCondition");
                        }
					}
                    else
                    {
                        // localStorage.clear();
                    }
					// this.cartStatus = response.data.cartdetails;
				}).catch((error) => {
							// localStorage.clear();
						console.log(error.response.data.message);
				});
    },
    logout()
		{
            this.$confirm("Are you sure you want to logout?","Logout","")
				.then(() => {
                                localStorage.clear();
                                Vue.prototype.$userData = '';
                                Vue.prototype.$placesAddress = '';
                                // this.$router.push('/');
                                location.reload();
                });
		},

  },
};
</script>

<template>
  <!-- <Layout> -->
    <div>
    <header class="header clearfix element_to_stick headerCk" v-class-on-scroll="{ class: 'sticky', offset: 200 }">
        <div class="container">
            <div class="row">
				<div class="col-md-4 col-lg-4 col-xl-4 noMobile noMobilenew">
            <div id="logo">
                <div class="contact" v-class-on-scroll="{ class: 'contactSticky', offset: 200 }">
                   <!-- <img src="img/logo.svg" width="162" height="35" alt="" class="logo_normal">
                    <img src="img/logo_sticky.svg" width="162" height="35" alt="" class="logo_sticky"> -->
                    <i class="icon_phone"></i><a href="tel:+917710999666" class="contactAnch anchor"><span> +91 771 099 9666</span></a>
                </div>
            </div>
            </div>
				<div class="col-md-4 col-lg-4 col-xl-4 align-center">
					<div id="mainLogo logo">
                        <a href="/" class="noMobile">
                            <img src="../../templateAssests/img/cklogo.png"  v-class-on-scroll="{ class: 'mainLogoHide', offset: 10 }" alt="" style="object-fit:contain;height:55px;">
                        </a>
						<a href="/">
							<img class="mainLogoImg mainLogoHide" v-class-on-scroll="{ class: 'mainLogoShow', offset: 200 }" src="../../templateAssests/img/cklogo.png" height="55" alt="">
						</a>
					</div>
				</div>
            <!-- /top_menu -->
            <a href="#0" class="login_reg align-left" style="top:33px;">
                <i class="icon_phone" style="color:#fff;"></i><a href="tel:+917710999666" class="contactAnch anchor"><span> +91 771 099 9666</span></a>
            </a>
            <!-- <a href="#0" class="open_close"> -->
                <!-- <i @click="toggleMenuDrawer" class="icon_menu"></i> -->
							<!-- <Drawer @close="toggleMenuDrawer" align="left" :maskClosable="true" :closeable="false">
                                             <div v-if="openMenuDrawer">
												 <section class="head_section">
													<div  v-if="userName != ''" class="row">
													 <a href="/profile/my-profile">
														<div class="align-left"> -->
															<!-- <figure> -->
																<!-- <img class="userPlaceImage" src="https://assets.cloudkitch.co.in/user_documents/avatar.jpg" height="50" width="50" alt=""> -->
																<!-- </figure> -->
														<!-- </div>
														<div class="align-left">
															<a href="/profile/my-profile" class="userName" style="margin:8px 0 0 0; color:#212121; font-size:14px;">{{userName}}</a>
														</div>
                                                     </a>
													</div>
													<div v-else class="row">
														<div class="align-left">
															<p class="loginReg" @click="openLoginModal">Login / Register</p>
														</div>
													</div>
												 </section>
												 <hr style="margin:0px;"> -->
												 <!-- headMobileMenu -->

												 <!-- <section class="navigation_section">
													<ul class="dropdown-ul navigation_ul align-left">
														<a href="/"><li><img src="../../templateAssests/img/ckgallery/CloudKitch_logo_solo.png" style="object-fit:contain;margin-right:10px;" width="25" height="25" alt="">Home</li></a>
														<a @click="redirectCart"><li><img src="../../templateAssests/img/ckgallery/Cart.png" width="25" height="25" style="object-fit:contain;margin-right:10px;" alt="">Cart</li></a>
														<a href="/profile/my-orders"><li><i class="icon_bag_alt" style="font-size:20px;margin-right:10px;"></i>My Orders</li></a>
														<a href="/profile/my-subscriptions"><li><i class="mdi mdi-calendar-clock" style="font-size:20px;margin-right:10px;"></i>My Subscriptions</li></a>
														<a href="/profile/my-address"><li><i class="far fa-address-book" style="font-size:20px;margin-right:10px;"></i>My Address</li></a>
														<a href="/profile/my-profile"><li><i class="mdi mdi-account" style="font-size:20px;margin-right:10px;"></i>Profile</li></a>
													</ul> 
												 </section>
												 <hr class="hrBorder"> -->
												 <!-- navigation_section -->

												 <!-- <section class="help_section">
													 <h3 class="helpHead align-left">Help & Support</h3>
													<ul class="dropdown-ul navigation_ul align-left">
														<li><a href="/policies/terms-and-condition"><i class="mdi mdi-file-document-multiple-outline" style="font-size:20px;margin-right:10px;"></i>Terms & Conditions</a></li>
														<li><a href="/policies/grievance-policy"><i class="mdi mdi-file-document-outline" style="font-size:20px;margin-right:10px;"></i>Grievance Policy</a></li>
														<li><a href="/policies/cookie-policy"><i class="mdi mdi-cookie-outline" style="font-size:20px;margin-right:10px;"></i>Cookie Policy</a></li>
														<li><a href="/policies/cancellation-refund"><i class="mdi mdi-cash-refund" style="font-size:20px;margin-right:10px;"></i>Cancellation & Refund</a></li>
														<li><a href="#"><i class="icon_mail_alt" style="font-size:20px;margin-right:10px;"></i>Contact Us</a></li>
													</ul> 
												 </section>
												 <hr class="hrBorder"> -->
												 <!-- help_section -->

												 <!-- <section class="other_section" v-if="userName != ''">
													 <h3 class="helpHead align-left">Other</h3>
													<ul class="dropdown-ul navigation_ul align-left">
														<li><a @click="logout"><i class="uil uil-sign-out-alt" style="font-size:20px;margin-right:10px;"></i>Logout</a></li>
													</ul> 
												 </section> -->

												 <!-- <hr class="hrBorder"> -->
												 <!-- navigation_section -->
                                                 
                                             <!-- </div>
                                        </Drawer> -->
            <!-- </a> -->
            <a class="login_reg align-right" v-class-on-scroll="{ class: 'hideLogin_reg', offset: 200 }">
                <b-dropdown
					variant="white"
					class="dropdown d-inline-block"
					toggle-class="header-item noti-icon"
					right
					menu-class="dropdown-menu-lg p-0 dropdown-menu-end user-menu"
					 v-if="userName != ''"
					>
					<template v-slot:button-content>
					<ul id="top_menu" class="drop_user">
						<li>
							<div class="dropdown user clearfix">
								<a href="#" data-toggle="dropdown">
									<figure><img src="https://assets.cloudkitch.co.in/user_documents/avatar.jpg" height="40" width="40" alt=""></figure><span class="userName" v-class-on-scroll="{ class: 'userNameSticky', offset: 200 }">{{userName}}</span>
								</a>
							</div>
						</li>
					</ul>
					</template>
					<simplebar style="max-height: 230px; max-width:144px;" data-simplebar>
					<div>
									<div>
										<ul class="dropdown-ul">
											<!-- <li><a href="#0"><i class="icon_cog"></i>Setting</a></li> -->
                                            <li><a href="/profile/profileDashBoard"><i class="icon_profile"></i>Profile</a></li>
											<li><a @click="logout"><i class="icon_key"></i>Log out</a></li>
										</ul>
									</div>
								</div>
					</simplebar>
					
					</b-dropdown>
                <nav v-else>
                    <a href="/" class="show-submenu loginMob">HOME</a>
                    <a href="#0" class="show-submenu loginMob">CONTACT US</a>
                    <!-- <a class="show-submenu regMob" @click="$bvModal.hide('modal-login')" v-b-modal.modal-register><button class="btn_1 gradient" type="submit">Register</button></a> -->
                </nav>
            </a>
<!-- /Mobile view -->

            <div class="col-md-4 col-lg-4 col-xl-4 align-right noMobile" style="margin-top:10px;">
            <b-dropdown
					variant="white"
					class="dropdown d-inline-block"
					toggle-class="header-item noti-icon"
					right
					menu-class="dropdown-menu-lg p-0 dropdown-menu-end user-menu"
					 v-if="userName != ''"
					>
					<template v-slot:button-content>
					<ul id="top_menu" class="drop_user">
						<li>
							<div class="dropdown user clearfix">
								<a href="#" data-toggle="dropdown">
									<figure><img src="https://assets.cloudkitch.co.in/user_documents/avatar.jpg" height="40" width="40" alt=""></figure><span class="userName" v-class-on-scroll="{ class: 'userNameSticky', offset: 200 }">{{userName}}</span>
								</a>
							</div>
						</li>
					</ul>
					</template>
					<simplebar style="max-height: 230px; max-width:144px;" data-simplebar>
					<div>
									<div>
										<ul class="dropdown-ul">
											<!-- <li><a href="#0"><i class="icon_cog"></i>Setting</a></li> -->
                                            <li><a href="/profile/profileDashBoard"><i class="icon_profile"></i>Profile</a></li>
											<li><a @click="logout"><i class="icon_key"></i>Log out</a></li>
										</ul>
									</div>
								</div>
					</simplebar>
					
					</b-dropdown>
					<nav class="main-menu" v-else>
					<ul>
                    <li class="submenu">
                        <a href="/" class="show-submenu">HOME</a>
                        <!-- <a href="/login" class="show-submenu">Login</a> -->
                    </li>
                    <li class="submenu">
                        <a href="#0" class="show-submenu">CONTACT US</a>
                        <!-- <a class="show-submenu" @click="$bvModal.hide('modal-login')" v-b-modal.modal-register><button class="btn_1 gradient" type="submit">Register</button></a> -->
                        <!-- <a href="/register" class="show-submenu"><button class="btn_1 gradient" type="submit">Register</button></a> -->
                    </li>
                </ul>
					</nav>
            </div>
            </div>
        </div>
    </header>
	<!-- /header -->
    <main> 
<div class="header-video">
            <div id="hero_video">
                <!-- <div class="shape_element one"></div>
                <div class="shape_element two"></div> -->
                <div class="opacity-mask d-flex align-items-center" data-opacity-mask="">
                    <div class="container displayText">
                        <div class="row ">
                            <div class="col-xl-9 col-lg-10 col-md-10 ml-45">
                              <!-- <img src="../../templateAssests/img/cklogo.png" alt="" class="ckLogo lazy"> -->
                                <span class="bannerTitle">We make food at work better!</span>
                                <br>
                                <p class="bannerDesc">We are building food programs that are more affordable and accessible for corporates.<br>
                                We bring exciting food choices that are truly loved by the employees.</p>
		                        <a href="#TELL-US" class="text-dark"><button type="submit" class="btn_1 regNowBtn" style="font-size:16px;font-family:'Poppins';">Register Now</button></a>
                                <!-- <p class="text-center mt-3 d-block"><a href="#emPanelForm" class="btn_1 medium gradient pulse_bt mt-2">Register Now</a></p> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <img id="teaser-video" class="teaser-video webVideo" src="../../templateAssests/img/corporate/top_banner.jpg">
            <!-- <img src="../../templateAssests/img/video_fix.png" class="header-video--media" data-video-src="video/intro" data-teaser-source="video/intro" data-provider="" data-video-width="1920" data-video-height="960"> -->
            <!-- <video autoplay="true" loop="loop" muted="" id="teaser-video" class="teaser-video webVideo"><source src="../../templateAssests/video/intro_web_video.mp4" type="video/mp4"><source src="video/intro.ogv" type="video/ogg"></video>
            <video autoplay="true" loop="loop" muted="" id="teaser-video" class="teaser-video mobVideo"><source src="../../templateAssests/video/intro_web_mobile_video.mp4" type="video/mp4"><source src="video/intro.ogv" type="video/ogg"></video> -->
            <!-- <div class="wave hero"></div> -->
        </div>
        <!-- /header-video -->

        <div class="shape_element_2 bg_white"  style="padding:0px 0 0px 0;">
            <div class="container" >
                <div class="row " style="padding:50px 0 60px 0;" >
                    <div class="col-lg-12">
                        <h2 class="poppins align-center" style="margin-bottom:35px;color: #212121 ;font-size: 34px; font-weight: 600 ;line-height:35px;">The importance of  <span style="color:#f37b70;">food@work.</span></h2>
                        <div class="row"> 
                            <div class="col-sm-4 py-2 infoCards" >
                                <div class="card newroundedbottom" >
                                        <img class="newroundedtop" src="../../templateAssests/img/corporate/basic-need-min.jpg" alt=""  style="width:100%">
                                        <div class="container-c">
                                            <h4  style="color:#f3796d;font-family:'Poppins';font-weight:600;">A basic need</h4> 
                                            <p class="card-text">Food@Work is a <span style="color:#222222;font-family:'Poppins';font-size:18px;font-weight:600;">welfare amenity</span> for people who tend to spend 10+ hours at work.<br>
                                            Traditionally workplace cafeterias were an obligation, now they are source of organisational value creation. 
                                            </p> 
                                        </div>
                                </div>
                        </div>
                        <div class="col-sm-4 py-2 infoCards">
                                <div class="card newroundedbottom">
                                        <img class="newroundedtop" src="../../templateAssests/img/corporate/war-of-talent-min.jpg" alt="" style="width:100%">
                                        <div class="container-c">
                                            <h4  style="color:#f3796d;font-family:'Poppins';font-weight:600;">The war for talent</h4> 
                                            <p class="card-text"><span style="color:#222222;font-family:'Poppins';font-size:18px;font-weight:600;">Controlling attrition rates</span> and maintaining good talent reduces employee cost.<br>
                                            Employees expect to have a good office experience including better food choices and convenience of purchase.
                                        </p> 
                                        </div>
                                </div>
                        </div>
                        <div class="col-sm-4 py-2 infoCards">
                                <div class="card newroundedbottom">
                                        <img class="newroundedtop" src="../../templateAssests/img/corporate/morale+productvt-min.jpg" alt="" style="width:100%">
                                        <div class="container-c">
                                            <h4 style="color:#f3796d;font-family:'Poppins';font-weight:600;">Morale + productivity</h4> 
                                            <p class="card-text">Great Workplaces treat food subsidies as <span style="font-weight:600;color:#222222;font-family:'Poppins';font-size:18px;">an investment for a healthy workforce.</span><br>
                                            Purposeful food programs enable collaboration, build camaraderie and increase employee creativity and productivity.
                                            </p> 
                                        </div>
                                </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- /shape_element_2 -->

        <div class="shape_element_2 bg_white"  style="padding:0px 0 0px 0;">
            <div class="container" >
                <div class="row " style="padding:0px 0 70px 0;" >
                        <h2 class="poppins align-center" style="margin-bottom:35px;color: #212121 ;font-size: 34px; font-weight: 600 ;">We aim to solve corporate food service’s  <span style="color:#f3766a;font-family:'Poppins';">top challenges.</span></h2>
                    <div class="col-lg-6 colWidth" style="margin-right:auto;">
                        <div class="row">
                           <div class="col-12">
                                <h5 class="foSection1_li_title">Food Service Operations.</h5>
                                <div>
                                    <p class="foSection1_li_desc foSection1_li_desc1">
                                            &bull;	&nbsp;	&nbsp;Office kitchens are difficult to operate and occupies expensive real estate.
                                            </p>
                                            <p class="foSection1_li_desc foSection1_li_desc1">
                                            &bull;	&nbsp;	&nbsp;Corporate administration and facilities management consumed in managing food service operations.
                                            </p>
                                    <p class="foSection1_li_desc foSection1_li_desc1">
                                            &bull;	&nbsp;	&nbsp;Lack information, techniques, tools or technology.
                                            </p>
                                <p class="foSection1_li_desc foSection1_li_desc1">
                                            &bull;	&nbsp;	&nbsp; Incur hidden costs while managing a non-core business activity.
                                            </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-5 colWidth">
                        <img class="" src="../../templateAssests/img/corporate/food-service ops-min.jpg" style="object-fit:cover;border-radius:10px;width:100%;height:100%;" alt="">
                        <!-- <img class="" src="../../templateAssests/img/ckgallery/47.jpg" height="700" style="width:80%;margin-left:17%;" alt="">  -->
                    </div>
                </div>
            </div>
        </div>
        <!-- /shape_element_2 -->

        <div class="shape_element_2 bg_white"  style="padding:0px 0 0px 0;">
            <div class="container" >
                <div class="row" style="padding:0px 0 70px 0;" >
                    <div class="col-lg-6 colWidth">
                        <img class="" src="../../templateAssests/img/corporate/fragmanted-value-chain-min.jpg" style="object-fit:cover;border-radius:10px;width:100%;height:100%;" alt="">
                        <!-- <img class="" src="../../templateAssests/img/ckgallery/47.jpg" height="700" style="width:80%;margin-left:17%;" alt="">  -->
                    </div>
                    <div class="col-lg-5 colWidth" style="margin-left:auto;">
                        <div class="row">
                           <div class="col-12 provideHead" style="margin-bottom:19px;">
                                <h5 class="foSection1_li_title">Fragmented Value Chain.</h5>
                                <div>
                                    <p class="foSection1_li_desc foSection1_li_desc1">
                                            &bull;	&nbsp;	&nbsp;Age old procurement of contracting food services.
                                            </p>
                                            <p class="foSection1_li_desc foSection1_li_desc1">
                                            &bull;	&nbsp;	&nbsp;Managing multiple food service contracts for cafeteria, meetings and events.
                                            </p>
                                    <p class="foSection1_li_desc foSection1_li_desc1">
                                            &bull;	&nbsp;	&nbsp;Pay management fee or minimum guarantee to food service operators in lieu of low volumes or low price.
                                            </p>
                                <p class="foSection1_li_desc foSection1_li_desc1">
                                            &bull;	&nbsp;	&nbsp; Unorganized system that wastes time and administration effort.
                                            </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- /shape_element_2 -->

        <div class="shape_element_2 bg_white"  style="padding:0px 0 0px 0;">
            <div class="container" >
                <div class="row " style="padding:0px 0 70px 0;" >
                    <div class="col-lg-6 colWidth" style="margin-right:auto;">
                        <div class="row">
                           <div class="col-12">
                                <h5 class="foSection1_li_title">Food Fatigue.</h5>
                                <div>
                                    <p class="foSection1_li_desc foSection1_li_desc1">
                                            &bull;	&nbsp;	&nbsp;Unable to provide employees with decent food choices due to fixed service providers.
                                            </p>
                                            <p class="foSection1_li_desc foSection1_li_desc1">
                                            &bull;	&nbsp;	&nbsp;Monotonous food experience that hurts employee morale.
                                            </p>
                                    <p class="foSection1_li_desc foSection1_li_desc1">
                                            &bull;	&nbsp;	&nbsp;Office cafeterias tend to be industrial and analog instead of rich and communal.
                                            </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-5 colWidth">
                        <img class="" src="../../templateAssests/img/corporate/food-fatig-min.jpg" style="object-fit:cover;border-radius:10px;width:100%;height:100%;" alt="">
                        <!-- <img class="" src="../../templateAssests/img/ckgallery/47.jpg" height="700" style="width:80%;margin-left:17%;" alt="">  -->
                    </div>
                </div>
            </div>
        </div>
        <!-- /shape_element_2 -->

         <div class="shape_element_2 bg_white"  style="padding:0px 0 0px 0;">
            <div class="container" >
                <div class="row" style="padding:0px 0 70px 0;" >
                    <div class="col-lg-12 bg-image" style="height:359px;border-radius: 10px;">
                      <div style="background-color: rgba(0, 0, 0, 0.3)!important; border-radius: 10px;" class="opacity-mask d-flex align-items-center" data-opacity-mask="">
                          <div class="container">
                                <div class="row ">
                                    <div class="col-xl-9 col-lg-10 col-md-10 ml-45">
                                    <!-- <img src="../../templateAssests/img/cklogo.png" alt="" class="ckLogo lazy"> -->
                                         <h2 class="fontnew" style="color:#ffffff;text-align:left;font-weight:600;font-family:'Poppins';">Food@Work is about digitising an integrated food<br><span style="color:#ffffff;">program</span><span style="color:#F3766A;"> curating great experience @work.</span></h2>
                                    </div>
                                </div>
                            </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- /shape_element_2 -->


        <div class="shape_element_2" id="TELL-US" ref="div1reg">
           
            <div class="container emPanelSection" >
                <div class="align-center">
                    <h2 style="font-weight:600;color:#f3766a;font-family:'Poppins ;"  class="Poppins">Tell us about yourself</h2>
                </div>
                <div  class="emPanelForm">
                    <form>
                    <b-form-group
                      label="Name"
                      label-for="formrow-firstname-input"
                      class="mb-3 "
                    >
                      <b-form-input
                        type="text"
                        id="formrow-firstname-input"
                        placeholder="Name"
                         style="font-family:Poppins !important;"
                        v-model="fullName"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group
                      label="Company"
                      label-for="formrow-firstname-input"
                      class="mb-3 Poppins "
                    >
                      <b-form-input
                        type="text"
                        id="formrow-firstname-input"
                        placeholder="Company"
                         style="font-family:Poppins !important;"
                        v-model="company"
                      ></b-form-input>
                    </b-form-group>
                    <div class="row">
                        <div class="col-6">
                             <b-form-group
                                label="Contact"
                                label-for="formrow-firstname-input"
                                class="mb-3 Poppins "
                                >
                                <b-form-input
                                    type="text"
                                    id="formrow-firstname-input"
                                    placeholder="Contact Number"
                                    style="font-family:Poppins !important;"
                                    v-model="mobileNo"
                                ></b-form-input>
                            </b-form-group>
                        </div>
                        <div class="col-6">
                             <b-form-group
                                label="Email Id"
                                label-for="formrow-firstname-input"
                                class="mb-3 Poppins "
                                >
                                <b-form-input
                                    type="text"
                                    id="formrow-firstname-input"
                                    placeholder="Email Id"
                                    style="font-family:Poppins !important;"
                                    v-model="emailID"
                                ></b-form-input>
                            </b-form-group>
                        </div>
                    </div>

                    <b-form-group
                      label="Location"
                      label-for="formrow-restaurant-input"
                      class="mb-3 "
                    >
                      <b-form-input
                        type="text"
                        id="formrow-restaurant-input"
                        placeholder="Location"
                         style="font-family:Poppins !important;"
                        v-model="location"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group
                      label="How can we help"
                      label-for="formrow-message-input"
                      class="mb-3 "
                    >
                      <b-form-input
                        type="text"
                        id="formrow-message-input"
                        placeholder="Message.."
                         style="font-family:Poppins !important;"
                         v-model="message"
                      ></b-form-input>
                    </b-form-group>
                    
                    <p v-if="emPanelError != ''" style="color:red;">{{emPanelError}}</p>
                    <p @click="addEmpanel" class="text-center d-block"><a href="#0" class="btn_1 medium" style="font-family: 'Poppins', sans-serif;">Submit</a></p>
                  </form>
                </div>
            </div>
        </div>
        <!-- /shape_element_2 -->

    </main>
  <!-- /main -->
<loginRegModal />
<termsAndCondition />
<footerLayout style="display:block!important;"/>
    </div>
    <!-- /Root Div  -->
    
</template>

<!-- SPECIFIC CSS -->
<style scoped src="../../templateAssests/css/home.css"></style>
<style scoped>
.colWidth
{
    width:47%;   
}
.regNowBtn
{
    padding: 0px!important;
    height: 45px;
    width: 175px;
}
.header-video p
{
    font-weight: 400;
}
.poppins
{
    font-family: 'Poppins', sans-serif !important;
}
label{

    font-family: 'Poppins', sans-serif;
}

.tell-usimage {
/* background-image:url('../../templateAssests/img/corporate/tell-us-about-yourself-min.jpg'); */
/* width:100%; */
 /* background-repeat:no-repeat;
 width:100%;
 height:auto; 
 */
  /* background:url('../../templateAssests/img/corporate/tell-us-about-yourself-min.jpg') no-repeat top;
	background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    height:auto; */
}

.newroundedbottom{
border-radius: 0px 0px 10px 10px !important;
}

.newroundedtop{
 border-radius: 10px 10px 0px 0px !important;
}

.newroundefull{
border-radius: 10px !important;
}

.bg-image {
    background:url('../../templateAssests/img/corporate/food@work-min.jpg') no-repeat top;
	background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    height:200px;
 /* background-image:url(''); */
 /* background-size: auto; */
 /* width:100%; */
 /* height:auto; */

}
.card-text {
font-family: Poppins;
font-style: normal;
font-weight: 400;
}
.main {
font-size:40px;
font-weight: 500;
text-align:center;
font-family: Poppins;
}
.card {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  min-height: 200px;
  /* width: 40%; */
  border-radius: 10px !important;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.container-c {
    font-size: 18px;
    height: 100%;
   padding: 12px 20px 30px;
}
.bannerTitle
{   font-size: 51px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600 !important;
    color:#663333 !important;
    
}
.bannerDesc
{
     font-family: 'Poppins', sans-serif;
    font-size: 22px;
    font-weight: 400;
    color:#F3766A;
    line-height:40px;
}
.ml-45
{
    margin-left: 45px;
}
.foSection1_li_title
{
    /* margin: 8px 0px; */
    margin: 0px 0px 30px;
    color: #212121;
    /* font-size: 16px; */
    font-size: 24px;
    /* font-weight: 600; */
    font-family: 'Poppins'!important;
}
.foSection1_li_desc
{
    /* color: #979797; */
    /* line-height:30px; */
    /* color: #626262; */
    color: #212121;
    /* font-size: 14px; */
     /* font-weight: 500;  */
    font-size: 18px;
  /* font-family: 'Poppins', sans-serif; */
    font-family: 'Poppins'!important;
}
.emPanelSection
{
    /* padding:50px; */
    padding-top:50px;
    padding-bottom:50px;
    width: 650px;
}
.foSection1_li_desc1
{
    text-align:start;
    font-family: 'Poppins', sans-serif;
    
}
.text-left{
    font-family: 'Poppins', sans-serif;
}
.bg_white
{
    background: #ffffff;
}

.header-video
{
    height:  425px !important;
}
.displayText
{
    padding-top: 62px;
}
.header-video img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    object-fit: contain;
}
.headerCk
{
    position: fixed;
}
.contact{
        font-size:14px;
        color:#fff;
    }
    .contactAnch{
        color:#fff;
    }
    .contactSticky{
      color:#000!important;
      /* margin-top:15px; */
    }
    .contactSticky .contactAnch{
      color:#000;
    }
    .anchor:hover, .anchor:focus{
        color:#e54750;
    }
    .a.btn_1:hover, .btn_1:hover{
        color:#fff;
    }
    .btn_1{
        font-weight:500;
        /* margin-left:93px; */
        /* width:175px; */
        color:#212121;
        padding:14px 14px;
        font-size:20px;
        background-color:#fec50b;
    }
    .userNameSticky{
        color:#000!important;
    }
    .main-cards{
        position:relative;
        top:-145px;
        z-index:9;
        /* z-index:100; */
    }
    .cards{
        max-width:222px;
        max-height:245.43px;
        border-radius:5px;
    }
    .cardsRow{
        justify-content:center;
    }
    .opacity-mask{
        background-color:rgba(0, 0, 0, 0.5);
    }
    .ckLogo {
          bottom: 43px;
           position: relative;
           object-fit: cover;
           height: 100px;
    }
    #logo{
        padding-top:14px;
    }
    .mainLogoImg{
        width: 100%;
        object-fit: contain;
    }
    .mainLogoShow{
        display: block!important;
        margin-top: 10px;
    }
    .mainLogoHide{
        display: none;
    }
    .modal-body{
        padding:30px!important;
    }
    .modalLoginText{
        font-size:24px;
        margin-bottom: 14px;
        color:#212121;
    }
    .emailInput{
        margin-bottom: px;
    }
    .otpBtn{
        height: 45px;
        background: #FFc60c;
        border: none;
        color: #212121;
        margin-bottom: 37px;
    }
    .divider{
        color:#eaeaea;
        margin-bottom: 29px;
    }
    .google img{
        margin-right: 28px;
    }
    .google{
        border: 1px solid #EAEAEA;
        box-sizing: border-box;
        border-radius: 5px;
        color:#212121;
        font-size: 14px;
    }
    a.social_bt.google:hover, a.social_bt.google:focus{
        border: 1px solid #777777;
    }
    .googleLoginBtn
    {
        width: 100%;
        background: transparent;
        border:none;
    }
    .signUptext{
        color: #212121;
        font-size: 14px;
    }
    .signUptext span a{
        color: #F3766A!important;
    }
    .modal-footer{
        height:50px!important;
    }
    .otpSection{
        margin-top: 40px;
    }
    .otpText{
        margin-bottom: 10px;
        color: #212121;
        font-size:14px;
    }
    .contactNumber{
        font-size:16px;
        color:#212121;
        margin-bottom: 17px;
    }
    .contactNumber a{
         color: #F3766A!important;
         font-size:13px;
    }
    .enterOTP{
        color:#777777!important;
        font-size:14px;
        margin-bottom:14px;
    }
    .otpInputs{
        margin-bottom: 16px;
    }
    .resendOTP{
        color: #777;
        margin-bottom: 15px;
         width:80%;
    }
    .resendOTP .timer{
        color: #F3766A;
       
    }
    .verifyotpBtn{
        width: 170px;
        height: 45px;
        background: #FFC60C;
        border-radius: 5px;
        border:none;
        margin-bottom: 19px;
    }
    #signUpConditions li{
        display:inline;
        margin-right: 10px;
        font-size: 10px;
        text-decoration: underline;
    }
    header{
        z-index: 101!important;
    }
    .closeModalDiv{
        float: right;
        padding-top: 6px;
        padding-right: 9px;
        cursor: pointer;
    }
    .inner-addon {
        position: relative;
    }
    .bxSearchModal{
        position: absolute;
        padding: 10px;
        pointer-events: none;
    }
    .errorDiv{
        font-size:14px;
        color: red;
    }
    .right-addon .bxSearchModal { right: 8px;font-size:22px;}
    .right-addon input { padding-right: 30px; border-radius: 20px;}
    /* @media screen and (max-width: 767px) {
           .conatiner{
             padding-left:0;
             padding-right:0;
           }
    } */
    </style>
    <style>
    .dropdown-toggle{
	margin-right: 10px!important;
    }
    .dropdown-toggle::after{
        display: none !important;
    }
    .dropdown-menu{
	    border-top: 3px solid #A4827D;
		width: 330px!important;
		max-width: 330px!important;
    }
    .user-menu{
        width: 144px!important;
        max-width: 144px!important;
        right: auto !important;
    }
    .dropdown-ul
    {
        list-style: none;
        padding: 0px!important;
    }
    .dropdown-ul li{
        padding: 12px 15px;
        color: #000;
    }
    .dropdown-ul li a{
        color: #000;
    }
    .dropdown-ul li a i{
        margin-right: 8px;
    }
    ul.dropdown-ul li a{
	    cursor: pointer;
    }
    .userPlaceImage
{
	object-fit: contain;
	border-radius: 25px;
}
.loginReg
{
	font-size: 18px;
	color: #F3766A;
}
.head_section
{
	margin-bottom: 20px;
}
/* .navigation_ul
{
	list-style-type: none;
	padding: 0;
} */
.navigation_ul li
{
	padding: 12px 0;
	font-size: 14px;
}
.navigation_ul li a
{
	color: #777;
}
.helpHead
{
	font-size: 16px;
	color: #777;
}
.login_reg
{
    display: none;
}
.login_reg a
{
    padding: 0 8px 10px;
}
.loginMob
{
    color: #fff;
}
.loginMob:hover, .loginMob:focus
{
    color: #e54750!important;
}
.hideLogin_reg
{
    display: none !important;;
}
.findFood
{
    max-width: 122px;
    position: absolute!important;
    top: 0px;
    right: 5px;
}
.infoCards
{
    width: 32%!important;
    margin: auto;
}

@media only screen and (min-width: 320px) and (max-width: 769px){
    .ml-45
    {
        margin-left: 0px!important;
    }
    .provideHead
    {
        margin-top: 35px;
    }
    .colWidth
    {
        width: 100%!important;
    }
    
}

@media only screen and (max-width: 600px) {
  
    .bannerTitle
{
   

font-size: 25px !important;
line-height: 30px !important;

}
.bannerclrfont{
    
font-size: 25px !important;
line-height: 30px !important;

}

.bannerDesc
{
    font-size: 22px;   
    line-height:30px;
}

.fontnew{
    font-size: 22px !important;
}
.pdding{
    padding-top: 20px;
}

.removpadd{
        /* padding-right: 0px; */
        padding: 0px 0px 0px 0px !important; 
    }
}
@media (max-width: 991px)
{

    .bannerTitle
{
   

font-size: 35px ;
line-height: 40px;

}
.bannerDesc
{
    font-size: 30px ;   
    line-height:30px ;
}
    .login_reg
    {
        display: block;
        position: absolute;
        left: 0px;
        top: 27px;
    }
    .findFood
    {
        margin: 0px 0 0 0!important;
        top: 5px;
        right: 15px;
    }
    .pdding{
    padding-top: 20px;
}
}
@media (max-width: 1024px)
{
    #hero_video {
        background: #ededed url(../../templateAssests/img/corporate/top_banner.jpg) center center no-repeat;
        /* background: #ededed url(../img/ckgallery/black.jpg) center center no-repeat; */
        background-size: cover;
        background-position: center;
    }

    .noMobilenew{
        visibility: hidden;
    }
}
    </style>




